import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSliders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/element/sliders', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSlider(ctx, slider) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/element/sliders/store', slider)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSlider(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/element/sliders/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSlider(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/element/sliders/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAlertTop(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/element/alert')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAlertTop(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/element/alert/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchModal(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/element/modal')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateModal(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/element/modal/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
