<template>
  <div>
    <!-- Table Container Card -->
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-card
          no-body
          class="py-2 px-2"
        >
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col
                cols="12"
                md="9"
                class="d-flex pl-0 align-items-center justify-content-start mb-1 mb-md-0"
              >
                <div class="">
                  <h3>Modal principal</h3>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="3"
                class="d-flex pl-0 align-items-center justify-content-start mb-1 mb-md-0"
              >
                <div class="">
                  <b-form-checkbox
                    v-model="modalData.isEnabled"
                    name="check-button"
                    switch
                    inline
                  >
                    {{ modalData.isEnabled ? 'ACTIVADO' : 'DESACTIVADO' }}
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
          </div>
          <validation-observer
            ref="refFormObserver"
            #default="{ handleSubmit }"
          >
            <b-form @submit.prevent="handleSubmit(onSubmit)">
              <b-row>
                <!--Description -->
                <b-col md="12">
                  <b-form-group
                    label="Descripción"
                    label-for="description"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Descripción"
                    >
                      <b-form-input
                        v-model="modalData.description"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- URL button-->
                <b-col md="12">
                  <b-form-group
                    label="URL Botón"
                    label-for="url-button"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="URL Botón"
                    >
                      <b-form-input
                        v-model="modalData.url"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Image button-->
                <b-col md="6">
                  <b-form-group
                    label="Imagen"
                    label-for="image"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Imagen"
                    >
                      <b-form-file
                        id="image-desktop"
                        ref="refInputEl"
                        v-model="modalData.image"
                        placeholder="(750x500 px)"
                        accept="image/*"
                        @input="renderImage"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

<!--                <b-col md="6">-->

<!--                  <b-form-group-->
<!--                    label="Tamaño"-->
<!--                    label-for="size"-->
<!--                  >-->
<!--                    <validation-provider-->
<!--                      #default="{ errors }"-->
<!--                      rules="required"-->
<!--                      name="Tamaño"-->
<!--                    >-->
<!--                      <b-form-select-->
<!--                        v-model="modalData.size"-->
<!--                        :options="sizeOptions"-->
<!--                      />-->
<!--                      <small class="text-danger">{{ errors[0] }}</small>-->
<!--                    </validation-provider>-->
<!--                  </b-form-group>-->
<!--                </b-col>-->

                <!-- submit button -->
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                  >
                    Actualizar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <div
          v-if="showImage"
          class=""
        >
          <b-img
            thumbnail
            fluid
            :src="showImage"
            alt="Modal principal"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormCheckbox, BFormFile, BFormSelect, BImg,
} from 'bootstrap-vue'
import {
  required, alpha, alphaDash,
} from '@validations'
import store from '@/store'
import elementStoreModule from '@/views/dramox/elements/elementStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BImg,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-elements'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, elementStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }
    const showImage = ref(null)
    const refInputEl = ref(null)
    const modalData = ref({
      description: '',
      url: '',
      isEnabled: false,
      image: null,
      size: '',
    })

    const sizeOptions = [
      { value: 'sm', text: 'SM' },
      { value: 'md', text: 'MD' },
      { value: 'lg', text: 'LG' },
    ]

    const { inputImageRenderer: renderImage } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign

      showImage.value = base64
    })

    const onSubmit = () => {
      const formData = new FormData()
      formData.append('description', modalData.value.description)
      formData.append('url', modalData.value.url)
      formData.append('image', modalData.value.image)
      formData.append('size', modalData.value.size)
      formData.append('isEnabled', modalData.value.isEnabled ? 'ACTIVADO' : 'DESACTIVADO')

      store.dispatch('app-elements/updateModal', { id: modalData.value.id, body: formData })
        .then(() => {
          showNotification({ title: 'Actualizado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        }).catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    store.dispatch('app-elements/fetchModal')
      .then(response => {
        if (response.data.payload.results) {
          const {
            url_button: url, image, ...rest
          } = response.data.payload.results
          Object.assign(modalData.value, { url }, rest)
          showImage.value = image
        }

      }).catch(() => {
        showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
      })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refInputEl,
      refFormObserver,
      getValidationState,

      renderImage,

      showImage,
      sizeOptions,
      modalData,
      onSubmit,
    }
  },
  data() {
    return {
      required,
      alpha,
      alphaDash,
    }
  },
}
</script>
